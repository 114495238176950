const landingPageStrings = {
  allAssetsHeadText:
    'Our Mission is to help companies reach their circular economy goals with our asset management solution.',
  bookDemoButtonText: 'Book a demo meeting',
  joinButtonText: 'Join wait list',

  problemSectionHeadText: 'Problems with manual asset management:',
  problemLeftSectionDescText:
    "Are you still struggling with manual asset management? Excel spreadsheets and other outdated methods can be costly, time-consuming and error-prone, leading to unnecessary complications and lost productivity. At trakr, we understand that asset management is a critical part of your business, and we're here to help.",
  tryTrakrNowButtonText: 'Try trakr now',
  costly: 'Costly',
  costlyDesc:
    'Managing assets manually can be a costly endeavor, with potential for mistakes, lost time, and missed opportunities. With trakr, you can streamline your asset management processes and minimise the costs associated with manual methods.',
  timeConsuming: 'Time-consuming',
  timeConsumingDesc:
    'Manual asset management can be incredibly time-consuming, especially when using outdated methods like spreadsheets. trakr offers a cloud-based solution that makes asset management quick and easy.',
  errorPone: 'Error prone',
  errorPoneDesc:
    'With manual asset management comes the risk of errors and inaccurate data, which can lead to costly mistakes. By using trakr, you can minimise errors and ensure reliable data, enabling you to make informed decisions that drive your business forward.',

  hours: 'Upto 40% efficiency increase',
  hoursDesc:
    'Trakr redifines work efficiency by optimizing asset management processes. Its streamlined approach ensures seamless tracking and maintenance, reducing downtime and errors. By offering real-time insights and predictive maintenance, Trakr empowers proactive decision-making, ultimately boosting overall productivity and efficiency within the company.',
  tonnes: 'Upto 25% product life cycle increase',
  tonnesDesc: `Trakr's structured scheduling system minimizes errors in asset management, enabling meticulous oversight and maintenance. By reducing error-prone issues, it leads to a substantial upto 25% increase in the product life cycle. This proactive approach ensures assets receive optimal care, significantly enhancing their durability and overall longevity.`,
  revenue: 'Upto 20% production capacity increase',
  revenueDesc: `A properly scheduled structure, supported by Trakr, diminishes error-prone issues, contributing to an upto 20% increase in plant production capacity. This organized system minimizes errors in processes, enabling efficient scaling while maintaining top-notch product quality.`,
  outOf: '7 out of 10',
  outOfDesc:
    'Today, 7 out of 10 assets in a company are disposed off after use. By being mindful of the lifecycle of each asset, a process made easy with trakr, you can elongate each assets lifecycle, making your asset function better for longer.',

  featuresSectionHeadText:
    'Features that set trakr apart from the competition:',
  assetTracking: 'Asset tracking',
  maintenanceScheduling: 'Maintenance scheduling',
  reservationSystem: 'Reservation system',
  checkInOut: 'Task verification',
  lostAndFound: 'Lost and found',
  reportingAnalytics: 'Reporting & analytics',
  mobileCompatibility: 'Mobile compatibility',
  cloudBased: 'Cloud-based',

  freqQuestionSectionHeadText: 'Frequently asked questions',
  freqQuestionOne: 'What is an asset?',
  freqAnwerOne:
    'Everything of value that a business needs to manage their work and keep track off. An example of an asset could be, but is not limited too, computers, tools, vehicles or keys. Trakr helps you efficiently manage all your assets in one place, saving you time and money.',
  freqQuestionTwo: 'Can I integrate trakr with other systems?',
  freqAnwerTwo:
    'Yes, trakr functions seamlessly with various existing systems. Let us know which integrations your company needs to streamline workflows within the apps you use every day.',
  freqQuestionThree: 'How does trakr track my assets?',
  freqAnwerThree:
    "trakr uses a cloud-based platform and connectable QR codes to connect assets to users, areas, or locations. With trakr's asset specification interface, users can check items in and out, reserve items, or report them as missing within the system. Our user-friendly interface makes it easy to manage your assets and keep track of their whereabouts.",
  freqQuestionFour: 'Is trakr going to be mobile friendly?',
  freqAnwerFour:
    "Yes! trakr's design automatically adjusts to mobile devices, allowing you to keep track of your assets on the go. Our platform is optimized for mobile use, so you can easily access your asset information from your smartphone or tablet.",
  freqQuestionFive:
    'How does trakr ensure the security and privacy of my data?',
  freqAnwerFive:
    'At trakr, we take data security and privacy seriously. Our platform is built with the latest security and GDPR protocols and measures in place to ensure that your data is safe and secure at all times. We use encryption to protect your data both in transit and at rest, and we also employ strict access controls to ensure that only authorized personnel can access your data.',
};

const navBarStrings = {
  whyChooseTrakr: 'Why choose trakr',
  stats: 'Stats',
  features: 'Features',
  faqs: 'FAQs',
};

export const englishStrings = { landingPageStrings, navBarStrings };
